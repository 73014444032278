import React, { useEffect, useState } from 'react'
import copy from 'copy-to-clipboard';

import { Edit as IconEdit, Copy as IconCopy, Link as IconLink, Openup as IconOpenup, Share as IconShare,Unlink as IconUnlink } from "../svg/components";
import Button from './Button.react'
import useLogin from './login-hook';
import useUser from './user-hook'
import useMyProfile from './myprofile-hook';
import { domain, projectName } from "../utils/env";
import Error from "./Error.react";
import ImgDefaultAvator from '../images/icon/default_avator.png'
import { CAN_USER_INVITE, TwitterActions } from '../services/constants';
import { shareOnTwitter } from '../utils/ShareUtils';
import useConncectWallet from './connectwallet-hook';
import useUpdateUserInfo from './useUpdateUserInfo';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletModalButton } from '@solana/wallet-adapter-react-ui';

function MyProfile() {

  const [followTwitter, setFollowTwitter] = useState(false)
  const [shareTwitter, setShareTwitter] = useState(false)
  const [joinDiscord, setJoinDiscord] = useState(false)
  const [joinTG, setJoinTG] = useState(false)

  const [copyWalletAddress, setCopyWalletAddress] = useState(false)
  const [copyInviteCode, setCopyInviteCode] = useState(false)
  const [copyInviteLink, setCopyInviteLink] = useState(false)

  const { onLogout } = useLogin()
  const { nickName, userIcon, walletAddress:walletAddressInSystem } = useUser();
  const { inviteCode, pageData, sendTaskData, errorMsg, cancelTaskData,airdropAmount } = useMyProfile();
  const{onConnectWallet,onDisconnectWallet}=useConncectWallet()


  const [sharePoints, setSharePoints] = useState(500)

  useEffect(() => {
    // setFollowTwitter(!!pageData?.XTask?.complete)
    setShareTwitter(!!pageData?.XTask?.complete)
    return () => {

    }
  }, [pageData?.XTask])

  useEffect(() => {
    setJoinTG(!!pageData?.TelegramTask?.complete)

    return () => {

    }
  }, [pageData?.TelegramTask])

  const initCopyStatus = () => {
    setCopyWalletAddress(false)
    setCopyInviteLink(false)
    setCopyInviteCode(false)
  }

  const onCopyWalletAddress = () => {
    initCopyStatus()

    if (walletAddressInSystem.length === 0) {
      return
    }
    copy(walletAddressInSystem)
    setCopyWalletAddress(true)

  }

  const onCopyInviteCode = () => {
    initCopyStatus()
    if (inviteCode.length === 0) {
      return
    }
    copy(inviteCode)
    setCopyInviteCode(true)
  }

  const onCopyInviteLink = () => {
    initCopyStatus()

    if (inviteCode.length === 0) {
      return
    }
    const url = `${domain}/invite/${inviteCode}`
    copy(url)
    setCopyInviteLink(true)
  }

  const onJoinX = async () => {
    initCopyStatus()
    if ((await sendTaskData('task-x-follow'))) {
      window.open("https://twitter.com/ai_eacc");
      setFollowTwitter(true)
    }

  }

  const onShareX = async () => {
    initCopyStatus()
    if(shareTwitter){
      const url = `${domain}/invite/${inviteCode}`
      shareOnTwitter(url)
    }
    else{
      if ((await sendTaskData('task-x-share'))) {
        const url = `${domain}/invite/${inviteCode}`
        shareOnTwitter(url)
        setShareTwitter(true)
      }
    }
  }

  const onJoinTG = async () => {
    initCopyStatus()

    if ((await sendTaskData('task-telegram-join'))) {
      window.open("https://t.me/effectiveaichat");
      setJoinTG(true)
    }
  }

  const onUnlinkX = async () => {
    initCopyStatus()
    if (await cancelTaskData('task-x-follow')) {
      window.open("https://twitter.com/ai_eacc");
      setFollowTwitter(false)
    }

  }

  const onUnlinkTG = async () => {
    initCopyStatus()
    if (await cancelTaskData('task-telegram-join')) {
      window.open("https://t.me/effectiveaichat");
      setJoinTG(false)
    }
  }


  const { update: updateUserInfo } = useUpdateUserInfo();


   // ===================== 连接钱包 =====================
   const { publicKey, connected, connecting, disconnect } = useWallet();
   useEffect(() => {
     console.log("publicKey***", publicKey);
     console.log("walletAddressInSystem***", walletAddressInSystem);
     if(walletAddressInSystem === undefined || !!walletAddressInSystem) return;
     if (!publicKey) return;
     if (!walletClick) {
       disconnect();
       return;
     }
     const base58 = publicKey.toBase58();
     console.log("wallet***", base58, connected, connecting);
     setWalletAddress(base58);
     return () => {};
   }, [publicKey,walletAddressInSystem]);
 
   const [walletAddress, setWalletAddress] = useState(walletAddressInSystem);
 
   useEffect(() => {
     console.log("task***walletAddressInSystem*", walletAddressInSystem);
     setWalletAddress(walletAddressInSystem);
 
     return () => {};
   }, [walletAddressInSystem]);
 
 
   useEffect(() => {
     (async () => {
       if (walletAddress && walletClick) {
         if (await onConnectWallet(walletAddress)) {
           updateUserInfo();
         }
 
         setWalletClick(false);
       }
     })();
 
     return () => {};
   }, [walletAddress]);
 
   const [walletClick, setWalletClick] = useState(false);
   const onConnectWalletClick = async (e) => {
     console.log("onConnectWalletClick***",walletAddress);
     setWalletClick(true);
     // signInSolana();
     if (!walletAddress) return;
     else {
       e.stopPropagation();
       await onConnectWallet(walletAddress) && updateUserInfo();
       setWalletClick(false);
     }
   };
 

  return <div className='relative flex flex-col flex-1 p-6 space-y-11'>
    <div className='flex flex-row items-center font-sans'>
      <div className='relative flex items-center justify-center mr-7'>
        <img className='size-[133px] bg-[#6DFFA7] rounded-full'
          // src={domain + userIcon} 
          src={ImgDefaultAvator}
        />
        {/* <button className='absolute inline-flex flex-row items-center'>
          <IconEdit stroke='#fff' />
          <span className='ml-2 text-sm leading-6 font-bold text-white'>Edit</span>
        </button>
        <input
          className='absolute inset-0 opacity-0'
          type='file'
          accept="image/png, image/jpeg"
        /> */}
      </div>
      <div className='text-[33px] leading-10 font-extrabold text-[#E2F2FF] tracking-wide mr-2'>{nickName}</div>
      {/* <button className='inline-flex flex-row items-center'>
        <IconEdit stroke='#3CEC82' />
        <span className='ml-2 text-sm leading-6 font-bold text-[#3CEC82]'>Edit</span>
      </button> */}
    </div>
    <ul className='flex flex-col font-sans space-y-4'>
      {walletAddressInSystem?.length !== 0 && <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
        <div className='text-base font-medium leading-5 text-white'>My connected wallet</div>
          <div className='flex flex-row items-center space-x-4'>
          <div className='text-sm leading-3 text-white'>{walletAddressInSystem}</div>
          <button
            disabled={walletAddressInSystem.length === 0}
            className='flex flex-row items-center text-[#3CEC82] disabled:cursor-not-allowed disabled:opacity-40'
            onClick={onCopyWalletAddress}>
            <span className='inline-flex text-lg'>
              <IconCopy />
            </span>
            <span className='ml-2 text-sm font-medium leading-6'>{copyWalletAddress ? 'Copied' : 'Copy'}</span>
          </button>
          <button onClick={async()=>{await onDisconnectWallet() && updateUserInfo();}}
            className='flex flex-row items-center text-[#3CEC82] disabled:cursor-not-allowed disabled:opacity-40'>
            <span className='inline-flex text-lg'>
              <IconUnlink />
            </span>
            <span className='ml-2 text-sm font-medium leading-6'>Disconnect</span>
          </button>
        </div>
      </li> || 
      <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
      <div className='text-base font-medium leading-5 text-white'>My connected wallet</div>
        <div className='flex flex-row items-center space-x-4'>
        <div className='text-sm leading-3 text-white'></div>

        <WalletModalButton
        children={
          <Button className={'-mr-5'} onClick={async()=>{ onConnectWalletClick()}}>
              <div className='flex flex-row items-center'>
                <span>Connect</span>
                <span className='inline-flex text-[8px] ml-2'>
                  <IconOpenup />
                </span>
              </div>
            </Button>
        }
      ></WalletModalButton>
      </div>
    </li>}
      {CAN_USER_INVITE && <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
        <div className='text-base font-medium leading-5 text-white'>My referral code</div>
        <div className='flex flex-row items-center space-x-4'>
          <div className='flex flex-row'>
            <span className='text-sm leading-3 text-[#9EA2B3]'>{domain}/invite/</span>
            <span className='text-sm leading-3 text-white'>{inviteCode}</span>
          </div>
          <button
            disabled={inviteCode.length === 0}
            className='flex flex-row items-center text-[#3CEC82] disabled:cursor-not-allowed disabled:opacity-40'
            onClick={onShareX}>
            <span className='inline-flex text-lg'>
              <IconShare />
            </span>
            <span className='ml-2 text-sm font-medium leading-6'>Share on X</span>
          </button>
          <button
            disabled={inviteCode.length === 0}
            className='flex flex-row items-center text-[#3CEC82] disabled:cursor-not-allowed disabled:opacity-40'
            onClick={onCopyInviteCode}>
            <span className='inline-flex text-lg'>
              <IconCopy />
            </span>
            <span className='ml-2 text-sm font-medium leading-6'>{copyInviteCode ? 'Copied' : 'Copy'} code</span>
          </button>
          <button
            disabled={inviteCode.length === 0}
            className='flex flex-row items-center text-[#3CEC82] disabled:cursor-not-allowed disabled:opacity-40'
            onClick={onCopyInviteLink}>
            <span className='inline-flex text-lg'>
              <IconLink />
            </span>
            <span className='ml-2 text-sm font-medium leading-6'>{copyInviteLink ? 'Copied' : 'Copy'} link</span>
          </button>
        </div>
      </li>}
      {/*{(!followTwitter && TwitterActions === 'follow') && <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
        <div className='text-base font-medium leading-5 text-white'>Follow our team @ai_eacc on X (+100 E/ACC Points)</div>
        {
          followTwitter ? (
            <div className='flex flex-row items-center space-x-4'>
              <div className='text-sm leading-3 text-white'>@ai_eacc</div>
              <button
                className='flex flex-row'
                onClick={onUnlinkX}
              >
                <span className='ml-2 text-sm font-medium leading-6 text-[#3CEC82]'>Unlink</span>
              </button>
            </div>
          ) : (
            <Button onClick={onJoinX}>
              <div className='flex flex-row items-center'>
                <span>Follow</span>
                <span className='inline-flex text-[8px] ml-2'>
                  <IconOpenup />
                </span>
              </div>
            </Button>
          )
        }
      </li>}*/}
      {(TwitterActions === 'share') && <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
        <div className='text-base font-medium leading-5 text-white'>Share your E/ACC referral on X (+500 E/ACC Points everyday)</div>
        {
          shareTwitter ? (
            <div className='flex flex-row items-center space-x-4'>
              <div
                className='flex flex-row'
              >
                <span className='ml-2 text-sm font-medium leading-6 text-[#3CEC82]'>Completed today</span>
              </div>
            </div>
          ) : (
            <Button onClick={onShareX}>
              <div className='flex flex-row items-center'>
                <span>Share now to earn {sharePoints} points</span>
                <span className='inline-flex text-[8px] ml-2'>
                  <IconOpenup />
                </span>
              </div>
            </Button>
          )
        }
      </li>}
      {/* <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
        <div className='text-base font-medium leading-5 text-white'>Join Effective AI’s community on Discord(+100 E/ACC Points)</div>
        {
          joinDiscord ? (
            <div className='flex flex-row items-center space-x-4'>
              <div className='text-sm leading-3 text-white'>@ai_eacc</div>
              <button className='flex flex-row'>
                <span className='ml-2 text-sm font-medium leading-6 text-[#3CEC82]'>Unlink</span>
              </button>
            </div>
          ) : (
            <Button>
              <div className='flex flex-row items-center'>
                <span>Join</span>
                <span className='inline-flex text-[8px] ml-2'>
                  <IconOpenup />
                </span>
              </div>
            </Button>
          )
        }
      </li> */}
      { <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
        <div className='text-base font-medium leading-5 text-white'>Join Effective AI’s community on TeleGram (+100 E/ACC Points)</div>
        {
          joinTG ? (
            <div className='flex flex-row items-center space-x-4'>
              {/*<div className='text-sm leading-3 text-white'>@ai_eacc</div>
              <button
                className='flex flex-row'
                onClick={onUnlinkTG}
              >
                <span className='ml-2 text-sm font-medium leading-6 text-[#3CEC82]'>Unlink</span>
          </button>*/}
          <div
          className='flex flex-row'
        >
          <span className='ml-2 text-sm font-medium leading-6 text-[#3CEC82]'>Completed</span>
        </div>
            </div>
          ) : (
            <Button onClick={onJoinTG}>
              <div className='flex flex-row items-center'>
                <span>Join</span>
                <span className='inline-flex text-[8px] ml-2'>
                  <IconOpenup />
                </span>
              </div>
            </Button>
          )
        }
      </li>}
    <li className='flex flex-row items-center justify-between h-[72px] px-12 bg-bg-gradient rounded-3xl'>
      <div className='text-base font-medium leading-5 text-white'>Claim your airdrop</div>
      <div className='flex flex-row space-x-4'>
          <Button onClick={() => { window.location.replace(process.env.REACT_APP_AIRDROP_URL) }}>
            <div className='flex flex-row items-center'>
              <span>Claim $ACC</span>
              <span className='inline-flex text-[8px] ml-2'>
                <IconOpenup />
              </span>
            </div>
          </Button>
          <Button onClick={() => { window.location.replace(process.env.REACT_APP_AIRDROP_SOL_URL) }}>
            <div className='flex flex-row items-center'>
              <span>Claim $SOL</span>
              <span className='inline-flex text-[8px] ml-2'>
                <IconOpenup />
              </span>
            </div>
          </Button>
      </div>
    </li>
    </ul>
    <div className='flex'>
      <Button onClick={onLogout}>Sign out</Button>
    </div>
    {errorMsg && <Error>{errorMsg}</Error>}
  </div>
}

export default MyProfile